import { Contact } from "./contact";
import { Address } from "./address";

export class Child {
  public first_name?: string = "";
  public last_name?: string = "";
  public dob?: string = "";
  public gender?: string = "";
  public email?: string = "";
  public phone_home?: string = "";
  public phone_other?: string = "";
  public phone_office?: string = "";
  public phone_fax?: string = "";
  public cfc_fax?: string = "";
  public race?: string = "";
  public address: Address = new Address();
  public contact_emergency: Contact = new Contact();
  public source: Contact = new Contact();
  public provider: Contact = new Contact();
  public guardian: Contact = new Contact();
}
