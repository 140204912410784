
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, email, max, min } from "vee-validate/dist/rules";
import { Referral } from "@/models/referral";
// import { ReferralClientApi } from "@/api/server/ReferralClient";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("email", {
  ...email,
  message: "A valid email is required",
});
extend("max", {
  ...max,
  message: "The max value has been exceeded",
});
extend("min", {
  ...min,
  message: "Please input a larger value",
});

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class ReferralCreate extends Vue {
  // public readonly value!: Referral;
  public countries = [];
  public regions = [];
  private readonly gender_options = [
    { text: "Male", value: "M" },
    { text: "Female", value: "F" },
  ];
  private loading = false;
  private menu = false;
  private readonly maxDob = new Date().toDateString();
  public e1 = 1;
  // private client!: ReferralClientApi;
  // public value!: ReferralClientApi.IReferralClient;
  // private get editedItem(): ReferralClientApi.IReferralClient {
  //   return !!this.value ? this.value : new ReferralClientApi.IReferralClient({});
  // }
  // private editedItem!: ReferralClientApi.IReferralClient;
  public editedItem = new Referral();
  // public editedItem = {
  //   address: {},
  //   child: new Child (),
  //   reasons: new Reasons(),
  //   regions: {
  //     condition_description: null,
  //     identified: false,
  //   },
  // };

  public filteredRegions(): boolean {
    return false;
  }
  // private get editedItem(): Referral {
  //   return !this.value ? this.value : new Referral();
  // }
}
