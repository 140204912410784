import { Address } from "./address";

export class Contact {
  public company_name?: string = "";
  public first_name?: string = "";
  public last_name?: string = "";
  public email?: string = "";
  public phone_home?: string = "";
  public phone_other?: string = "";
  public phone_office?: string = "";
  public phone_fax?: string = "";
  public type?: string = "";
  public address: Address = new Address();
}
