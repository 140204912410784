export class Reasons {
  areas?: string;
  at_risk?: string;
  at_risk_description?: string;
  aware?: string;
  condition_description?: string;
  developmental_delay?: string;
  identified: string | undefined;
  other?: string;
}
