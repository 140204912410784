import { Child } from "./child";
import { Contact } from "./contact";
import { Reasons } from "./reasons";

export class Referral {
  public authorization: boolean | undefined;
  public referral_date: string | undefined;
  public child: Child = new Child();
  public provider: Contact = new Contact();
  public referrer: Contact = new Contact();
  public reasons: Reasons = new Reasons();
}
